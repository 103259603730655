import { IRoomsSearchParams, ITreeRooms } from 'interfaces';

export const updateHiddenRoomsParams = (
  treeRooms: ITreeRooms[],
  searchParams: URLSearchParams,
  updatedTreeRooms?: ITreeRooms[]
) => {
  const showClansParams = searchParams.get('showClans');

  const currentParams: IRoomsSearchParams[] = showClansParams
    ? JSON.parse(showClansParams)
    : [];

  const unhiddenTreeRooms = updatedTreeRooms?.filter(
    ({ isHidden, rooms }) => rooms.some((room) => !room.isHidden) || !isHidden
  );

  if (unhiddenTreeRooms?.length) {
    const convertedParams = unhiddenTreeRooms.map(({ clanId, rooms }) =>
      rooms.filter((room) => !room.isHidden).length === rooms.length
        ? {
            clanId,
          }
        : {
            clanId,
            hiddenRooms: rooms.flatMap((room) =>
              room.isHidden ? room.livekitName : []
            ),
          }
    );

    const filteredParams = convertedParams.filter(({ hiddenRooms }) =>
      treeRooms.some((treeRoom) =>
        !hiddenRooms?.length
          ? true
          : treeRoom.rooms?.length === hiddenRooms?.length
      )
    );

    const filteredParamsByHiddenRooms = filteredParams.filter(
      ({ hiddenRooms }) => hiddenRooms
    );

    if (
      unhiddenTreeRooms?.length === treeRooms.length &&
      filteredParams.length === treeRooms.length &&
      !filteredParamsByHiddenRooms.length
    ) {
      searchParams.delete('showClans');

      return {
        ...Object.fromEntries(searchParams),
        showAllClans: 'true',
      };
    }

    if (!currentParams.length) {
      searchParams.delete('showAllClans');

      return {
        ...Object.fromEntries(searchParams),
        showClans: JSON.stringify(convertedParams),
      };
    }

    return {
      ...Object.fromEntries(searchParams),
      showClans: JSON.stringify(convertedParams),
    };
  }

  searchParams.delete('showClans');
  searchParams.delete('showAllClans');

  return searchParams;
};

export const updateHiddenClansParams = (
  searchParams: URLSearchParams,
  clanId: string,
  treeRooms: ITreeRooms[],
  currentRooms: ITreeRooms[],
  isHidden?: boolean
) => {
  const showClansParams = searchParams.get('showClans');

  const showAllClans = searchParams.get('showAllClans');

  const currentParams: IRoomsSearchParams[] = showClansParams
    ? JSON.parse(showClansParams)
    : [];

  if (currentParams.length) {
    const filteredParamsByClanId = currentParams.filter(
      (param) => param.clanId === clanId
    );

    if (filteredParamsByClanId.length) {
      const newParams = currentParams.flatMap((param) =>
        param.clanId !== clanId
          ? param
          : param.hiddenRooms
          ? isHidden
            ? []
            : { clanId }
          : []
      );

      if (!newParams.length) {
        searchParams.delete('showClans');
        searchParams.delete('showAllClans');

        return searchParams;
      }

      const filteredParamsByUnhiddenRooms = newParams.filter(
        (clan) => !clan.hiddenRooms
      );

      if (
        newParams.length === treeRooms.length &&
        filteredParamsByUnhiddenRooms.length === newParams.length
      ) {
        searchParams.delete('showClans');

        return {
          ...Object.fromEntries(searchParams),
          showAllClans: 'true',
        };
      }

      return {
        ...Object.fromEntries(searchParams),
        showClans: JSON.stringify(newParams),
      };
    } else {
      const newParams = [...currentParams, { clanId }];

      const filteredParamsByUnhiddenRooms = newParams.filter(
        (clan) => !clan.hiddenRooms
      );

      if (
        newParams.length === treeRooms.length &&
        filteredParamsByUnhiddenRooms.length === newParams.length
      ) {
        searchParams.delete('showClans');

        return {
          ...Object.fromEntries(searchParams),
          showAllClans: 'true',
        };
      }

      return {
        ...Object.fromEntries(searchParams),
        showClans: JSON.stringify(newParams),
      };
    }
  }

  if (currentRooms.length) {
    const convertedClans = currentRooms.flatMap((room) =>
      room.clanId !== clanId ? { clanId: room.clanId } : []
    );

    if (showAllClans) {
      if (!convertedClans.length) {
        searchParams.delete('showClans');
        searchParams.delete('showAllClans');

        return searchParams;
      }

      searchParams.delete('showAllClans');

      return {
        ...Object.fromEntries(searchParams),
        showClans: JSON.stringify(convertedClans),
      };
    }

    if (currentRooms.length === 1 && !convertedClans.length) {
      searchParams.delete('showClans');

      return {
        ...Object.fromEntries(searchParams),
        showAllClans: 'true',
      };
    }

    return {
      ...Object.fromEntries(searchParams),
      showClans: JSON.stringify([...currentParams, { clanId }]),
    };
  }

  searchParams.delete('showClans');

  return {
    ...Object.fromEntries(searchParams),
    showAllClans: 'true',
  };
};

export const updateSearchParams = (
  currentParams: URLSearchParams,
  showClans: IRoomsSearchParams[],
  showAllStreams: boolean,
  showAllOtherStreams: boolean
) => {
  if (showAllStreams && !showAllOtherStreams) {
    currentParams.delete('showClans');

    return {
      ...Object.fromEntries(currentParams),
      showAllClans: JSON.stringify(true),
    };
  }

  if (!showAllStreams && !showAllOtherStreams) {
    currentParams.delete('showAllClans');

    return {
      ...Object.fromEntries(currentParams),
      showClans: JSON.stringify(showClans),
    };
  }

  if (showAllStreams && showAllOtherStreams) {
    return {
      ...Object.fromEntries(currentParams),
      showClans: JSON.stringify(showClans),
    };
  }

  if (showClans.length) {
    return {
      ...Object.fromEntries(currentParams),
      showClans: JSON.stringify(showClans),
    };
  }

  currentParams.delete('showClans');
  currentParams.delete('showAllClans');

  return currentParams;
};
