import { FC, MouseEvent, useCallback, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { useClickOutside } from 'hooks';
import { ReactComponent as Down } from 'images/newIcons/down.svg';
import { IOption } from 'interfaces';

import TextInput from '../TextInput';

interface SelectProps {
  value: IOption[] | IOption;
  options: IOption[];
  onSelect: (value: IOption[] | IOption) => void;
  label?: string;
  placeholder?: string;
  className?: string;
  classNameOptions?: string;
  disabled?: boolean;
  isMultiple?: boolean;
  isOptionSearch?: boolean;
  isOptionEmpty?: boolean;
  emptyOptionText?: string;
}

export const Select: FC<SelectProps> = ({
  value,
  options: optionsSelect,
  onSelect,
  label,
  placeholder,
  className,
  classNameOptions,
  disabled,
  isMultiple,
  isOptionSearch,
  isOptionEmpty = true,
  emptyOptionText = 'Не выбрано',
}) => {
  const [isOpen, setOpen] = useState(false);
  const [options, setOptions] = useState(optionsSelect);
  const [searchValue, setSearchValue] = useState('');

  const selectRef = useRef<HTMLDivElement>(null);

  useClickOutside<HTMLDivElement, void>(selectRef, () => {
    setOpen(false);
    setSearchValue('');
    setOptions(optionsSelect);
  });

  const handleSelectOption = (event: MouseEvent, option: IOption) => {
    event.stopPropagation();

    if (isMultiple && Array.isArray(value)) {
      const selectedValue = value.find(
        (selected) => selected.value === option.value
      );

      if (selectedValue) {
        const filteredValue = value.filter(
          (selected) => selected.value !== option.value
        );

        onSelect(filteredValue);
      } else {
        onSelect([...value, option]);
      }
    } else {
      onSelect(option);
    }
  };

  const handleOpenOptions = (event: MouseEvent) => {
    event.stopPropagation();

    if (disabled) return;

    setOpen(!isOpen);
  };

  const text = useMemo(
    () =>
      Array.isArray(value)
        ? value.map((el) => el.label).join(', ')
        : value.label,
    [value]
  );

  const selectedValue = useCallback(
    (option: IOption) =>
      Array.isArray(value)
        ? value.find((selected) => selected.value === option.value)
        : value.value === option.value,
    [value]
  );

  const handleResetValue = (event: MouseEvent) => {
    event.stopPropagation();

    isMultiple ? onSelect([]) : onSelect({ value: '', label: '' });
  };

  const handleChangeSearch = (value: string) => {
    const filteredOptions = optionsSelect.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase())
    );

    setOptions(filteredOptions);

    setSearchValue(value);
  };

  return (
    <div className="flex w-full relative flex-col gap-[8px]" ref={selectRef}>
      {label && <label className="tpg-c2 text-tpg_base">{label}</label>}
      <div
        className={cn(
          'flex flex-row w-full transition delay-200 cursor-pointer bg-ultrablack min-h-[60px] pr-[16px]',
          { '!cursor-default': disabled },
          className
        )}
      >
        <div
          className="flex flex-row items-center justify-between w-full"
          onClick={handleOpenOptions}
        >
          <div className="w-full flex flex-col">
            {placeholder && (
              <span className="tpg-c2 text-tpg_light">{placeholder}</span>
            )}
            <div className="flex flex-row justify-between items-center">
              <span className="tpg-c1">{text ? text : emptyOptionText}</span>
              <div className="flex items-center w-[20px] h-[20px]">
                <Down
                  className={cn('[&>path]:fill-tpg_base [&>path]:transition', {
                    'rotate-180': isOpen,
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          className={cn(
            'w-full absolute bg-ultrablack top-[105%] max-h-[212px] left-0 z-[3] flex flex-col cursor-pointer rounded-[10px] border border-solid border-tpg_light overflow-auto',
            classNameOptions
          )}
        >
          {isOptionSearch && (
            <TextInput
              onChange={handleChangeSearch}
              value={searchValue}
              placeholder="Начните поиск"
              searchIcon
              inputClassName="!rounded-t-[10px] !rounded-b-none !py-[9px] !pr-[12px] !pl-[28px] !border-none"
              className="[&>div>svg]:!ml-[6px] [&>div>svg]:!w-5 [&>div>svg]:!h-5 border-b border-solid border-tpg_light"
              size="m"
            />
          )}
          {(searchValue || isOptionEmpty) && (
            <div onClick={handleResetValue}>
              {!options.length && searchValue ? (
                <div className="w-full flex min-h-[35px] justify-center items-center">
                  <span className="text-tpg_base tpg-c2">
                    Ничего не найдено
                  </span>
                </div>
              ) : (
                !searchValue &&
                isOptionEmpty && (
                  <div className="flex min-h-[35px] px-[16px] hover:bg-dark items-center">
                    <span
                      className={cn('tpg-c2 text-tpg_base', {
                        '!text-dark_product': !text,
                      })}
                    >
                      {emptyOptionText}
                    </span>
                  </div>
                )
              )}
            </div>
          )}
          {options.map((option) => (
            <div
              key={option.value}
              className="flex min-h-[35px] px-[16px] hover:bg-dark items-center"
              onClick={(event) => handleSelectOption(event, option)}
            >
              <span
                className={cn('tpg-c2 text-tpg_base', {
                  '!text-dark_product': selectedValue(option),
                })}
              >
                {option.label}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
