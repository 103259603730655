import { Key, MouseEvent, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IAccountRole, ISelectOption } from 'interfaces';
import { authSelector, playerSelector, updateMfaRequiredThunk } from 'store';
import { getAccountsThunk } from 'store/slices/player/actions';
import {
  ROLES_SELECT_OPTIONS,
  rolesTranslateMap,
  rolesValuesMap,
  TPlayerTable,
} from 'types/player';

import { Switch } from 'components/ui';
import { Loader } from 'components/ui/Loader';
import STable, { STableFetchParams } from 'components/ui/Table/STable';
import { STableCell } from 'components/ui/Table/STableCell';
import { IFilterValue } from 'components/ui/Table/STableHeader';
import { changeClanValue } from 'utils';

import { getAccounts } from '../../api/account';

export const STATUS_SELECT_OPTIONS: ISelectOption[] = [
  { value: false, label: 'Активен' },
  { value: true, label: 'Забанен' },
];

const getHeadCellsData = (clans: ISelectOption[]) => [
  {
    id: 'player',
    numeric: false,
    disablePadding: false,
    label: 'Позывной',
    sortableProperty: 'name',
  },
  {
    id: 'external_id',
    numeric: false,
    disablePadding: false,
    label: 'Логин',
    sortableProperty: 'external_id',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Роль',
    possibleValues: ROLES_SELECT_OPTIONS.map((el) => el.label),
  },
  {
    id: 'banned',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
    possibleValues: STATUS_SELECT_OPTIONS.map((el) => el.label),
  },
  {
    id: 'native_clan',
    numeric: false,
    disablePadding: false,
    label: 'Родное подразделение',
    possibleValues: clans.map((el) => el.label),
  },
  {
    id: 'clan',
    numeric: false,
    disablePadding: false,
    label: 'Подразделения',
    possibleValues: clans.map((el) => el.label),
  },
  {
    id: 'mfa_required',
    numeric: false,
    disablePadding: false,
    label: 'Двухфакторная аутентификация',
  },
];

const changeRoleValue = (values: IAccountRole[]) =>
  values.length
    ? values
        .reduce((acc: string[], value: IAccountRole) => {
          if (value.clan_id !== '00000000000000000000000000') {
            if (value.clan_name) {
              acc.push(
                `${value.clan_name} - ${rolesTranslateMap[value.role_name]}`
              );
            } else {
              acc.push(`${rolesTranslateMap[value.role_name]}`);
            }
          }

          return acc;
        }, [])
        .join(', ')
    : 'Не выбрана';

export const UsersTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { pending } = useAppSelector(playerSelector);

  const { id: accountId } = useAppSelector(authSelector);

  const [selectedFilters, setSelectedFilters] = useState<IFilterValue[]>([]);
  const [possibleValues, setPossibleValues] = useState<ISelectOption[]>([]);

  const [searchParams] = useSearchParams();

  const search = searchParams.get('search');

  const convertValues = (
    values: string[],
    options: ISelectOption[]
  ): string[] =>
    values
      .map((value) => {
        const option = options.find((el) => el.label === value);

        return option ? String(option.value) : null;
      })
      .filter((value) => value !== null) as string[];

  const fetchRows = useMemo(
    () => async (params: STableFetchParams) => {
      const newFilterValues: IFilterValue[] = selectedFilters.map(
        (selectedFilter) => {
          let convertedValues: string[];

          switch (selectedFilter.id) {
            case 'banned':
              convertedValues = convertValues(
                selectedFilter.values,
                STATUS_SELECT_OPTIONS
              );

              break;
            case 'role':
              convertedValues = convertValues(
                selectedFilter.values,
                ROLES_SELECT_OPTIONS
              );

              break;
            case 'clan':
              convertedValues = convertValues(
                selectedFilter.values,
                possibleValues
              );

              break;
            case 'native_clan':
              convertedValues = convertValues(
                selectedFilter.values,
                possibleValues
              );

              break;
            default:
              convertedValues = [];
          }

          return {
            id: selectedFilter.id,
            values: convertedValues,
          };
        }
      );

      const response = await getAccounts({
        search: search,
        offset: params.start,
        limit: params.size,
        filters: newFilterValues,
      }).then((r) => r.data);

      const clans =
        response?.possible_values.find((el) => el.id === 'clan')?.values || [];

      setPossibleValues(
        clans.map((el) => ({
          label: el.name,
          value: el.id,
        }))
      );

      return {
        rows: response?.accounts || [],
        total: response?.total || 0,
      };
    },
    [search, selectedFilters]
  );

  const ENTITIES_HEAD_CELLS = useMemo(
    () => getHeadCellsData(possibleValues),
    [possibleValues]
  );

  const handleRowClick = (_row: TPlayerTable, rowId: Key) => {
    navigate(`/admin-panel/users/${rowId}`);
  };

  const handleTwoFactorChange = (id: string, isMfaRequired: boolean) => {
    dispatch(
      updateMfaRequiredThunk({
        account_id: id,
        mfa_required: !isMfaRequired,
      })
    ).then(() => {
      dispatch(getAccountsThunk());
    });
  };

  const handleCellClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  if (pending)
    return (
      <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center">
        <Loader />
      </div>
    );

  return (
    <div className="relative">
      <STable<TPlayerTable, string>
        headCells={ENTITIES_HEAD_CELLS}
        rowIdExtractor={(row) => row?.id}
        fetchRows={fetchRows}
        handleRowClick={handleRowClick}
        classNames={{
          root: 'group !h-[calc(100vh-148px)]',
          row: 'group hover:!bg-ultrablack',
          cell: 'group-hover:!bg-[inherit] !border-b-tpg_light !bg-light',
          body: '[&>tr>td]:h-[93px]',
        }}
        onFiltersChange={setSelectedFilters}
        selectedFilters={selectedFilters}
        isPagination
        renderRowCells={(row, props) => {
          const roleKey = row.roles
            .map(({ role_name }) => role_name)
            ?.join(',');

          const clans = changeClanValue(row.roles);

          return (
            <>
              <STableCell
                {...props}
                key={`name-${row.name}`}
                propName="name"
                cellType="text"
              >
                {row.name}
              </STableCell>
              <STableCell
                {...props}
                key={`external_id-${row.name}`}
                propName="external_id"
                cellType="text"
              >
                {row.external_id}
              </STableCell>
              <STableCell
                {...props}
                key={roleKey}
                propName="role"
                cellType="text"
              >
                {changeRoleValue(row.roles)}
              </STableCell>
              <STableCell
                {...props}
                key={`${row.status}-${row.name}`}
                propName="status"
                cellType="text"
              >
                {row.banned ? 'Забанен' : 'Активный'}
              </STableCell>
              <STableCell
                {...props}
                key={`${row.clan_name}-${row.name}`}
                propName="clans"
                cellType="text"
              >
                {row.clan_name || 'Не выбрано'}
              </STableCell>
              <STableCell
                {...props}
                key={`${clans.length ? clans : 'Нет'}-${row.name}`}
                propName="clans"
                cellType="text"
              >
                {clans || 'Не выбрано'}
              </STableCell>
              <STableCell
                {...props}
                key={`mfaRequired-${row.name}-${!!row.mfa_required}`}
                propName="mfa_required"
                cellType="checkbox"
                isEditMode={true}
              >
                <div onClick={handleCellClick}>
                  <Switch
                    checked={!!row.mfa_required}
                    disabled={
                      row.id === rolesValuesMap.root &&
                      accountId !== rolesValuesMap.root
                    }
                    onChange={() =>
                      handleTwoFactorChange(row.id, !!row.mfa_required)
                    }
                  />
                </div>
              </STableCell>
            </>
          );
        }}
      />
    </div>
  );
};
