import { AxiosError, AxiosResponse } from 'axios';

import { notify } from 'utils';

import { errorMessage, getErrorMessage } from './getErrorMessage';

export const requestHandler =
  <T, Args extends any[]>(
    func: (...args: Args) => Promise<AxiosResponse<T>>,
    options?: {
      defaultErrorMessage?: string;
      defaultSuccessMessage?: string;
      callback?: (error: AxiosError, defaultMessage: string) => string;
    }
  ) =>
  async (...rest: Parameters<typeof func>) => {
    try {
      const { data } = await func(...rest);

      if (options?.defaultSuccessMessage) {
        notify.success(options.defaultSuccessMessage);
      }

      return { data };
    } catch (error) {
      const message =
        error instanceof AxiosError
          ? getErrorMessage(error, options)
          : errorMessage;

      notify.error(message);

      return { error };
    }
  };
