export enum reducersNames {
  MAP = 'map',
  AUTH = 'auth',
  LOGGED_IN = 'logged-in',
  STREAM = 'stream',
  CLAN = 'clan',
  PLAYER = 'player',
  ACCOUNT = 'account',
  ROOM = 'room',
  RECORDS = 'records',
}

export enum asyncActionsNames {
  GET_ROLES = 'getRoles',
  LOGIN = 'login',
  CHECK_MFA_PARAMS = 'checkMfaParams',
  LOGGED_IN = 'loggedIn',
  GET_PRIVILEGES = 'getPrivileges',
  GET_CLANS = 'getClans',
  GET_CLAN = 'getClan',
  GET_CLAN_MEMBERS = 'getClanMembers',
  GET_CLAN_RECORDS = 'getClanRecords',
  CREATE_CLAN = 'createClan',
  UPDATE_CLAN = 'updateClan',
  DELETE_CLAN = 'deleteClan',
  REGISTER = 'register',
  SET_CLAN_MEMBER = 'setClanMember',
  UPDATE_CLAN_MEMBER = 'updateClanMember',
  DELETE_CLAN_MEMBER = 'deleteClanMember',
  GET_CLANS_ROOMS = 'getClanRooms',
  GET_ALL_ROOMS = 'getAllRooms',
  GET_STREAM_TOKEN = 'getStreamToken',
  GET_ALL_STREAM_TOKENS = 'getAllStreamTokens',
  CREATE_ROOM = 'createRoom',
  CREATE_ROOM_INGRESS = 'createRoomIngress',
  DELETE_ROOM = 'deleteRoom',
  DELETE_ROOM_INGRESS = 'deleteRoomIngress',
  GET_RECORDING_SIGNAL = 'getRecordingSignal',
  GET_ALL_PLAYERS = 'getAllPlayers',
  CET_CLAN_RECORDS = 'getClanRecords',
  GET_RECORD = 'getRecord',
  GET_ACCOUNT = 'getAccount',
  GET_ACCOUNT_ME = 'getAccountMe',
  CREATE_ACCOUNT = 'createAccount',
  UPDATE_ACCOUNT = 'updateAccount',
  UPDATE_ACCOUNT_ROLE = 'updateAccountRole',
  UPDATE_ACCOUNT_EMAIL = 'updateAccountEmail',
  UPDATE_ACCOUNT_PASSWORD = 'updateAccountPassword',
  DELETE_ACCOUNT = 'deleteAccount',
  UPDATE_MFA_REQUIRED = 'updateMfaRequired',
}
