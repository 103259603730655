import { FC, ReactNode, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ReactComponent as Down } from 'images/newIcons/down.svg';
import { ReactComponent as PinIcon } from 'images/newIcons/pin.svg';
import { ReactComponent as PinActiveIcon } from 'images/newIcons/pinActive.svg';
import { IRoom } from 'interfaces/room';
import { roomActions } from 'store/slices/room';
import { roomSelector } from 'store/slices/room/selectors';

interface DropdownProps {
  label: string;
  isActive: boolean;
  id?: string;
  rooms?: IRoom[];
  children?: ReactNode;
  action?: ReactNode;
  isOpen?: boolean;
  sublabel?: string | ReactNode;
  labelClassName?: string;
  onParentClick?: (id?: string) => void;
  onChildClick?: (title: string, id: string) => void;
}

export const Dropdown: FC<DropdownProps> = ({
  label,
  isActive,
  id,
  rooms,
  children,
  action,
  isOpen: isOpenProps,
  sublabel,
  labelClassName,
  onParentClick,
  onChildClick,
}) => {
  const [isOpen, setOpen] = useState(isOpenProps || false);

  const { treeRooms } = useAppSelector(roomSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const handleArrowClick = () => {
    setOpen(!isOpen);
  };

  const handlePinClick = (publisherName: string, flag: boolean) => {
    if (!id) return;

    const updatedTreeRooms = treeRooms.map((value) =>
      value.clanId === id
        ? {
            ...value,
            rooms: value.rooms.map((room) =>
              room.publisherName === publisherName
                ? { ...room, isPinned: flag }
                : room
            ),
          }
        : value
    );

    dispatch(roomActions.updateTreeRooms(updatedTreeRooms));

    const pinnedParams = updatedTreeRooms.flatMap(
      ({ isPinned, clanId, rooms }) => {
        const result = isPinned ? [{ clanId }] : [];

        const pinnedRooms = rooms.flatMap((room) => {
          if (room.isPinned) {
            return room.publisherName;
          }

          return [];
        });

        return pinnedRooms.length
          ? [...result, { clanId, rooms: pinnedRooms }]
          : result;
      }
    );

    if (pinnedParams.length) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        pinned: JSON.stringify(pinnedParams),
      });
    } else {
      searchParams.delete('pinned');

      setSearchParams(searchParams);
    }
  };

  return (
    <div className="flex flex-col w-full gap-[6px]">
      <div className="flex items-center cursor-pointer gap-1">
        <Down
          onClick={handleArrowClick}
          className={cn('[&>path]:fill-tpg_base [&>path]:transition', {
            'rotate-[270deg]': !isOpen,
            '[&>path]:!fill-bright_product': isActive,
          })}
        />
        <div className="flex flex-row w-full justify-between">
          <div
            className="flex flex-row gap-[5px] items-center"
            onClick={() => onParentClick?.(id)}
          >
            <span
              className={cn(
                'tpg-c2 text-bright_product transition',
                {
                  '!text-tpg_base': !isActive,
                },
                !isActive && labelClassName
              )}
            >
              {label}
            </span>
            {sublabel && (
              <span
                className={cn('tpg-c2 text-bright_product transition', {
                  '!text-tpg_base': !isActive,
                })}
              >
                {sublabel}
              </span>
            )}
          </div>
          {<div className="pl-[5px]">{action}</div>}
        </div>
      </div>
      {isOpen &&
        (rooms
          ? rooms.map(
              ({
                publisherName,
                isHidden: isHiddenRoom,
                livekitName,
                isPinned,
              }) => (
                <div
                  key={publisherName}
                  className="w-full flex flex-row justify-between ml-[32px] pr-[16px]"
                >
                  <span
                    onClick={() => id && onChildClick?.(livekitName, id)}
                    className={cn('cursor-pointer tpg-c2 text-bright_product', {
                      'text-tpg_base': isHiddenRoom,
                    })}
                  >
                    {publisherName}
                  </span>
                  {!isPinned && (
                    <PinIcon
                      className="cursor-pointer"
                      onClick={() => handlePinClick(publisherName, true)}
                    />
                  )}
                  {isPinned && (
                    <PinActiveIcon
                      className="cursor-pointer"
                      onClick={() => handlePinClick(publisherName, false)}
                    />
                  )}
                </div>
              )
            )
          : children)}
    </div>
  );
};
