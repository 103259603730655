import { FC, ReactNode } from 'react';

import { Header } from 'components/Header';

import './style.scss';

type TLayoutProps = {
  children: ReactNode;
  rightHeaderButton?: ReactNode;
  leftContent?: ReactNode;
  centralContent?: ReactNode;
  сontentPanelMenu?: ReactNode;
  isPageControlPanel?: boolean;
  isShowHeader?: boolean;
  isStartStream?: boolean;
  resetOptions?: () => void;
};

export const MainLayout: FC<TLayoutProps> = ({
  isPageControlPanel = true,
  isShowHeader = true,
  children,
  rightHeaderButton,
  centralContent,
  leftContent,
  сontentPanelMenu,
  isStartStream,
  resetOptions,
}) => (
  <main className="main-container flex flex-col">
    {isShowHeader && (
      <Header
        rightButton={rightHeaderButton}
        centralContent={centralContent}
        leftContent={leftContent}
        isPageControlPanel={isPageControlPanel}
        сontentPanelMenu={сontentPanelMenu}
        isStartStream={isStartStream}
        resetOptions={resetOptions}
      />
    )}
    <div className="flex-auto overflow-auto p-[24px]">{children}</div>
  </main>
);
