import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useAppDispatch, useAppSelector, useClickOutside } from 'hooks';
import { ITreeRooms } from 'interfaces';
import { roomActions } from 'store/slices/room';
import { roomSelector } from 'store/slices/room/selectors';

import {
  updateHiddenClansParams,
  updateHiddenRoomsParams,
} from 'components/FilterStreams/utils';
import { Tag } from 'components/ui/Tag';
import { roomHideController } from 'utils';

interface IPinnedRooms {
  id: string;
  clanName: string;
  publisherName?: string;
  livekitName?: string;
  isPinned?: boolean;
  isHidden?: boolean;
  countRooms?: number;
}

const INDENT_DISTANCE = 5;

const BUTTON_WIDTH = 250;

const PADDING = 12;

const calculateTagWidth = (text: string) => {
  const tempDiv = document.createElement('div');
  tempDiv.style.position = 'absolute';
  tempDiv.style.visibility = 'hidden';
  tempDiv.style.whiteSpace = 'nowrap';
  tempDiv.innerText = text;

  document.body.appendChild(tempDiv);

  const width = tempDiv.offsetWidth;

  document.body.removeChild(tempDiv);

  return width;
};

export const PinnedStreams = () => {
  const [unhiddenTags, setUnhiddenTags] = useState<IPinnedRooms[]>([]);

  const [hiddenTags, setHiddenTags] = useState<IPinnedRooms[]>([]);

  const [isHiddenTagsOpen, setHiddenTagsOpen] = useState(false);

  const { treeRooms } = useAppSelector(roomSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const containerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  useClickOutside<HTMLDivElement, void>(containerRef, () =>
    setHiddenTagsOpen(false)
  );

  const pinnedRooms: IPinnedRooms[] = useMemo(
    () =>
      treeRooms.flatMap(({ isPinned, clanId, clanName, isHidden, rooms }) => {
        const result: IPinnedRooms[] = isPinned
          ? [
              {
                id: clanId,
                clanName,
                isHidden,
                isPinned,
                countRooms: rooms.length,
              },
            ]
          : [];

        const pinnedRooms = rooms.flatMap((room) => {
          if (room.isPinned) {
            return {
              id: clanId,
              clanName: clanName,
              isHidden: room.isHidden,
              isPinned: room.isPinned,
              publisherName: room.publisherName,
              livekitName: room.livekitName,
            };
          }

          return [];
        });

        return result.concat(pinnedRooms);
      }),
    [treeRooms]
  );

  const handleTagClick = (clanId: string, livekitName?: string) => {
    if (!clanId) return;

    if (livekitName) {
      const updatedTreeRooms = roomHideController(
        treeRooms,
        clanId,
        livekitName
      );

      dispatch(roomActions.updateTreeRooms(updatedTreeRooms));

      const newParams = updateHiddenRoomsParams(
        treeRooms,
        searchParams,
        updatedTreeRooms
      );

      setSearchParams(newParams);

      return;
    }

    let currentRooms: ITreeRooms[] = treeRooms;

    const selectedClan = currentRooms.find((room) => room.clanId === clanId);

    if (selectedClan?.rooms.length) {
      selectedClan?.rooms.forEach(({ livekitName }) => {
        const updatedTreeRooms = roomHideController(
          currentRooms,
          clanId,
          livekitName,
          !selectedClan.isHidden
        );

        if (updatedTreeRooms) {
          currentRooms = updatedTreeRooms;

          dispatch(roomActions.updateTreeRooms(updatedTreeRooms));
        }
      });
    } else {
      const updatedRooms = currentRooms.map((room) =>
        room.clanId === selectedClan?.clanId
          ? { ...room, isHidden: !room.isHidden }
          : room
      );

      currentRooms = updatedRooms;

      dispatch(roomActions.updateTreeRooms(updatedRooms));
    }

    const newParams = updateHiddenClansParams(
      searchParams,
      clanId,
      treeRooms,
      currentRooms
    );

    setSearchParams(newParams);
  };

  useEffect(() => {
    const calculateTags = () => {
      const filterButton = document.getElementById('filterButton');
      const rightContent = document.getElementById('rightContent');

      const filterButtonWidth = filterButton?.offsetWidth || 0;
      const rightContentWidth = rightContent?.offsetWidth || 0;
      const totalWidth = window.innerWidth;

      const widthBetweenContainers =
        totalWidth - filterButtonWidth - rightContentWidth - BUTTON_WIDTH;

      const visibleTags: IPinnedRooms[] = [];

      const otherTags: IPinnedRooms[] = [];

      const availableWidth =
        widthBetweenContainers -
        (pinnedRooms.length - visibleTags.length > 0
          ? BUTTON_WIDTH
          : INDENT_DISTANCE);

      let totalTagWidth = 0;

      pinnedRooms.forEach((tag) => {
        const tagWidth = calculateTagWidth(
          `${tag.publisherName || tag.clanName}${
            tag.countRooms ? ` (${tag.countRooms})` : ''
          }`
        );

        if (totalTagWidth + tagWidth + PADDING <= availableWidth) {
          totalTagWidth += tagWidth + PADDING;
          visibleTags.push(tag);
        } else {
          otherTags.push(tag);
        }
      });

      setUnhiddenTags(visibleTags);
      setHiddenTags(otherTags);
    };

    calculateTags();

    window.addEventListener('resize', calculateTags);

    return () => window.removeEventListener('resize', calculateTags);
  }, [pinnedRooms]);

  return (
    <div className="flex flex-row justify-start gap-[4px] pr-[4px]">
      {unhiddenTags.map(
        ({ id, isHidden, clanName, countRooms, publisherName, livekitName }) =>
          id && (
            <Tag
              key={publisherName || clanName}
              className={cn(
                'bg-ultrablack hover:bg-ultrablack !px-[12px] cursor-pointer',
                {
                  '!bg-dark_product': !isHidden,
                }
              )}
              label={`${publisherName || clanName}${
                countRooms ? ` (${countRooms})` : ''
              }`}
              onClick={() => handleTagClick(id, livekitName)}
            />
          )
      )}
      {!!hiddenTags.length && (
        <div ref={containerRef} className="relative">
          <Tag
            label={
              hiddenTags.length === pinnedRooms.length
                ? `${hiddenTags.length} закрепленных`
                : `Ещё ${hiddenTags.length} закрепленных`
            }
            className="!bg-ultrablack !px-[12px]"
            onClick={() => setHiddenTagsOpen(!isHiddenTagsOpen)}
          />
          {isHiddenTagsOpen && (
            <div className="flex flex-col items-start justify-start gap-[6px] absolute bg-ultrablack border border-solid rounded-[10px] border-tpg_light top-[30px] left-0 w-[215px] px-[16px] py-[6px]">
              {hiddenTags.map(
                ({
                  clanName,
                  id,
                  countRooms,
                  isHidden,
                  publisherName,
                  livekitName,
                }) => (
                  <span
                    key={publisherName || clanName}
                    className={cn('tpg-c2 cursor-pointer', {
                      'text-bright_product': !isHidden,
                    })}
                    onClick={() => handleTagClick(id, livekitName)}
                  >
                    {`${publisherName || clanName}${
                      countRooms ? ` (${countRooms})` : ''
                    }`}
                  </span>
                )
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
