import { createAsyncThunk } from '@reduxjs/toolkit';
import { recordAdapter, recordsAdapter } from 'api/helpers/recordsAdapter';
import { getClanRecords, getRecord } from 'api/records';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import { IRecord } from 'interfaces/record';

export const getClanRecordsThunk = createAsyncThunk<
  { clanId: string; records: IRecord[] },
  { clanId: string; params?: Record<string, any> }
>(
  `${reducersNames.RECORDS}/${asyncActionsNames.GET_CLAN_RECORDS}`,
  async (request, thunkAPI) => {
    const { clanId, params } = request;

    const { data, error } = await getClanRecords({ clanId, params });

    if (data) {
      return { clanId, records: recordsAdapter(data) };
    }

    return thunkAPI.rejectWithValue(error);
  }
);

export const getRecordThunk = createAsyncThunk<IRecord, string>(
  `${reducersNames.RECORDS}/${asyncActionsNames.GET_RECORD}`,
  async (request, thunkAPI) => {
    const { data, error } = await getRecord(request);

    if (data) {
      return recordAdapter(data);
    }

    return thunkAPI.rejectWithValue(error);
  }
);
