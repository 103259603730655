import { errorMessages, successMessages } from 'constants/messages';
import {
  ILoggedInResponse,
  IMeMfaResponse,
  IMfaParams,
  IMfaRequiredParams,
} from 'interfaces';
import axios from 'services/axios';

import { requestHandler } from './utils/requestHandler';

export const checkMfaParams = requestHandler((data: IMfaParams) =>
  axios.post<ILoggedInResponse>('/mfa', data)
);

export const updateMfaRequired = requestHandler(
  ({ account_id, mfa_required }: IMfaRequiredParams) =>
    axios.put(`/accounts/${account_id}/mfa-settings`, {
      mfa_required,
    }),
  {
    defaultErrorMessage: errorMessages.UPDATE_MFA_REQUIRED,
    defaultSuccessMessage: successMessages.SAVE_DATA,
  }
);

export const getMeMfa = requestHandler((mfaState: string) =>
  axios.post<IMeMfaResponse>('/accounts/me/mfa', { mfa_state: mfaState })
);
