import { AxiosError } from 'axios';

export const errorMessage =
  'Что-то пошло не так, пожалуйста, попробуйте позднее.';

export const getErrorMessage = (
  error: AxiosError,
  options?: {
    defaultErrorMessage?: string;
    callback?: (error: AxiosError, defaultMessage: string) => string;
  }
) => {
  if (error?.response) {
    const { status } = error.response;

    const defaultMessage =
      (options?.defaultErrorMessage &&
        options?.callback &&
        options?.callback(error, options.defaultErrorMessage)) ||
      options?.defaultErrorMessage ||
      errorMessage;

    switch (status) {
      case 400:
        return 'Некорректный запрос. Пожалуйста, проверьте корректность введённых данных и повторите попытку';
      case 403:
        return 'Доступ запрещён. У вас нет прав для выполнения этого запроса. Если это ошибка, обратитесь к администратору';
      case 500:
        return 'На сервере произошла внутренняя ошибка. Попробуйте позже';
      case 503:
        return 'Сервис временно недоступен. Повторите запрос позже';
      default:
        return defaultMessage;
    }
  }

  return errorMessage;
};
