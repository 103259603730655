import * as React from 'react';
import { Key, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import cn from 'classnames';
import { useDebounce } from 'usehooks-ts';

import {
  NOTHING_WAS_FOUND_MESSAGE,
  STREAMS_COLORS,
} from '../../../constants/routes';
import { TSetState } from '../../../types';

import { STableCellExtraProps } from './STableCell';
import { IFilterValue, STableHeadCell, STableHeader } from './STableHeader';

import './style.scss';
import styles from './STable.module.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: STREAMS_COLORS.DARK_PRODUCT,
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#E0C2FF',
      light: '#F5EBFF',
      // dark: will bes calculated from palette.secondary.main,
      contrastText: '#47008F',
    },
  },
});

export interface STableFetchParams {
  size: number;
  start: number;
  searchQuery?: string;
  filter?: IFilterValue[];
}

interface RowsWithTotalCount<R> {
  rows: R[];
  total: number;
}

interface STableProps<R, S> {
  isEditMode?: boolean;
  fetchRows: (params: STableFetchParams) => Promise<RowsWithTotalCount<R>>;
  searchQuery?: string;
  handleSelectedRows?: (rows: R[]) => void;
  handleRowClick?: (row: R, id: Key) => void;
  handleRowUpdate?: (sourceRow: R) => R;
  rowIdExtractor: (row: R) => Key;
  renderRowCells: (row: R, props: STableCellExtraProps<R>) => React.ReactNode;
  headCells?: STableHeadCell<S>[];
  classNames?: {
    root?: string;
    table?: string;
    row?: string;
    cell?: string;
    body?: string;
    header?: string;
  };
  isPagination?: boolean;
  role?: string;
  isCheckbox?: boolean;
  selectedFilters?: IFilterValue[];
  onFiltersChange?: TSetState<IFilterValue[]>;
}

const DEFAULT_ROWS_PER_PAGE = 25;

export const getTablePagingInitialParamsBasedOnUrl = (
  searchParams: URLSearchParams,
  searchQueryFromProps?: string
) => {
  const urlSizeParam = searchParams.get('size');
  const urlPageParam = searchParams.get('page');
  const urlQueryParam = searchParams.get('query') || searchQueryFromProps;

  return {
    rowsPerPage: urlSizeParam ? +urlSizeParam : DEFAULT_ROWS_PER_PAGE,
    page: urlPageParam ? +urlPageParam : 0,
    query: urlQueryParam || '',
  };
};

export default function STable<R, S>({
  isCheckbox = true,
  ...props
}: STableProps<R, S>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialTableParamsFromUrl = getTablePagingInitialParamsBasedOnUrl(
    searchParams,
    props.searchQuery
  );

  const [sortAsc, setSortAsc] = React.useState(false);

  const [sortBy, setSortBy] = React.useState<S | undefined>(undefined);
  const [selectedKeys, setSelectedKeys] = React.useState<readonly Key[]>([]);
  const [page, setPage] = React.useState(initialTableParamsFromUrl.page);
  const [dense, setDense] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(
    initialTableParamsFromUrl.rowsPerPage
  );

  const [rows, setRows] = React.useState<R[]>([]);
  const [totalCount, setTotalCount] = React.useState<number>(0);

  const debouncedSearchQuery = useDebounce(props.searchQuery, 200);

  useEffect(() => {
    setSearchParams((prev) => {
      rowsPerPage != DEFAULT_ROWS_PER_PAGE &&
        prev.set('size', `${rowsPerPage.toString()}`);

      page != 0 && prev.set('page', `${page.toString()}`);
      debouncedSearchQuery && prev.set('query', `${debouncedSearchQuery}`);

      return prev;
    });
  }, [debouncedSearchQuery, page, rowsPerPage, sortBy]);

  useEffect(() => {
    props
      .fetchRows({
        size: rowsPerPage,
        start: page * rowsPerPage,
        searchQuery: debouncedSearchQuery,
        filter: props.selectedFilters,
      })
      .then((r) => {
        setRows(r.rows);
        setTotalCount(r.total);
      });
  }, [
    props.fetchRows,
    props.selectedFilters,
    rowsPerPage,
    page,
    debouncedSearchQuery,
  ]);

  useEffect(() => {
    if (sortAsc) {
      const sortedRows = [...rows].sort((a, b) => {
        const rowA = a?.[sortBy as keyof R];
        const rowB = b?.[sortBy as keyof R];

        if (typeof rowA === 'string' && typeof rowB === 'string') {
          if (rowA.toLowerCase() < rowB.toLowerCase()) return -1;

          if (rowA.toLowerCase() > rowB.toLowerCase()) return 1;

          return 0;
        }

        if (typeof rowA === 'number' && typeof rowB === 'number')
          return rowA - rowB;

        console.log(
          'Несовместимые или неподдерживаемые типы данных для сортировки'
        );

        return 0;
      });

      setRows(sortedRows);
    } else {
      props
        .fetchRows({
          size: rowsPerPage,
          start: page * rowsPerPage,
          searchQuery: debouncedSearchQuery,
        })
        .then((r) => {
          setRows(r.rows);
          setTotalCount(r.total);
        });
    }
  }, [sortBy, sortAsc]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: S | undefined
  ) => {
    const isAsc = sortBy === property && sortAsc;

    setSortAsc(!isAsc);
    setSortBy(property);
  };

  // TODO handle selectAll properly including remote items? see @useTableCheck once implement check logic
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map(props.rowIdExtractor);

      setSelectedKeys(newSelected);

      return;
    }

    setSelectedKeys([]);
  };

  const onRowSelected = (key: Key) => {
    const selectedIndex = selectedKeys.indexOf(key);
    let newSelected: readonly Key[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedKeys, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedKeys.slice(1));
    } else if (selectedIndex === selectedKeys.length - 1) {
      newSelected = newSelected.concat(selectedKeys.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedKeys.slice(0, selectedIndex),
        selectedKeys.slice(selectedIndex + 1)
      );
    }

    setSelectedKeys(newSelected);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: Key) => selectedKeys.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const renderTableRow = (row: R, index: number) => {
    const rowId = props.rowIdExtractor(row);
    const isItemSelected = isSelected(rowId);

    const handleRowUpdate = (updatedRow: R) => {
      const updated =
        (props.handleRowUpdate && props.handleRowUpdate(updatedRow)) ||
        updatedRow;

      const currentRowIndex = rows.indexOf(row);

      if (currentRowIndex >= 0) {
        const updatedRows = [...rows];

        updatedRows[currentRowIndex] = updated;
        setRows(updatedRows);
      }
    };

    const handleRowNumberClick = () => {
      props.handleSelectedRows && onRowSelected(rowId);
    };

    return (
      <TableRow
        hover
        onClick={() => props.handleRowClick?.(row, rowId)}
        role={props.role ?? 'checkbox'}
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={rowId}
        selected={isItemSelected}
        sx={{ cursor: 'pointer' }}
        className={props.classNames?.row}
      >
        {isCheckbox && (
          <TableCell
            padding="checkbox"
            onClick={handleRowNumberClick}
            className={cn('first-column', props.classNames?.cell)}
          >
            <div className="flex justify-center text-primary">{index + 1}</div>
          </TableCell>
        )}
        {props.renderRowCells(row, {
          handleRowUpdate,
          key: index,
          isEditMode: props.isEditMode || false,
          row,
          className: props.classNames?.cell,
        })}
      </TableRow>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={cn(styles['table-root'], props.classNames?.root)}>
        <Table
          stickyHeader
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          style={{ overflowX: 'initial' }}
          className={props.classNames?.table}
          size={'medium'}
        >
          {props.headCells && (
            <STableHeader
              headCells={props.headCells}
              allowSelectRows={props.handleSelectedRows !== undefined}
              numSelected={selectedKeys.length}
              sortAsc={sortAsc}
              sortBy={sortBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              className={props.classNames?.header}
              selectedFilters={props.selectedFilters}
              onFiltersChange={props.onFiltersChange}
            />
          )}
          {searchParams && !rows.length && (
            <div className="absolute z-[2] left-1/2 -translate-x-1/2 top-[70px]">
              <span className="text-tpg_base tpg-c2 !text-lg">
                {NOTHING_WAS_FOUND_MESSAGE}
              </span>
            </div>
          )}
          <TableBody className={props.classNames?.body}>
            {rows.map((r, idx) => renderTableRow(r, idx + page * rowsPerPage))}
          </TableBody>
        </Table>
        {props.isPagination && rows.length > 0 && (
          <TablePagination
            labelRowsPerPage="Строк на странице"
            labelDisplayedRows={(args) =>
              `${args.from}-${args.to} из ${args.count}`
            }
            rowsPerPageOptions={[100, 50, 25, 10]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </div>
    </ThemeProvider>
  );
}
