import { FC, ReactNode, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useBreakPoint, useClickOutside } from 'hooks';
import { ReactComponent as FilterIcon } from 'images/newIcons/filter.svg';
import { ReactComponent as FilterActiveIcon } from 'images/newIcons/filter-active.svg';

interface FilterButtonProps {
  isOpen: boolean;
  onClick: (flag: boolean) => void;
  content: ReactNode;
  rightPosition?: string;
  topPosition?: string;
  label?: string;
  className?: string;
  classNameContent?: string;
  isShowIcon?: boolean;
  isActive?: boolean;
}

export const FilterButton: FC<FilterButtonProps> = ({
  isOpen,
  onClick,
  content,
  rightPosition,
  topPosition,
  label,
  className,
  classNameContent,
  isShowIcon = true,
  isActive,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside<HTMLDivElement, void>(containerRef, () => onClick(false));

  useEffect(
    () => () => {
      onClick(false);
    },
    []
  );

  const handleClick = () => {
    onClick(!isOpen);
  };

  const breakPoint = useBreakPoint();

  return (
    <div
      id="filterButton"
      ref={containerRef}
      className={cn({
        relative: breakPoint === 'mobile',
      })}
    >
      <div
        className={cn(
          'cursor-pointer h-[20px] gap-[4px] flex flex-row group justify-center items-center min-w-[140px] hover:text-bright_product transition',
          { '!text-bright_product': isOpen || isActive },
          className
        )}
        onClick={handleClick}
      >
        {isShowIcon &&
          (isOpen || isActive ? (
            <FilterActiveIcon className="group-hover:[&>g>path]:fill-bright_product w-[16px] h-[16px] [&>g>path]:transition" />
          ) : (
            <FilterIcon className="[&>path]:fill-tpg_title group-hover:[&>path]:fill-bright_product w-[16px] h-[16px] [&>path]:transition" />
          ))}
        {label && <span className="tpg-c2 whitespace-nowrap">{label}</span>}
      </div>
      {isOpen && (
        <div
          className={cn(
            'absolute min-w-[380px] flex flex-col bg-ultrablack border border-solid rounded-[10px] border-tpg_light z-10 top-0',
            { 'min-w-[330px]': breakPoint === 'mobile' },
            classNameContent
          )}
          style={{
            right: rightPosition,
            top: topPosition,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {content}
        </div>
      )}
    </div>
  );
};
