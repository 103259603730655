import { errorMessages, successMessages } from 'constants/messages';
import axios from 'services/axios';

import { getMemberErrorMessage } from 'utils';

import { TRegisterRequest } from './types/account';
import { requestHandler } from './utils/requestHandler';

export type BaseJSON = { [key: string]: string | number | BaseJSON };

export const register = requestHandler(
  ({ email, password, password2 = password, name }: TRegisterRequest) =>
    axios.post('/register', { email, password, password2, name }),
  {
    defaultErrorMessage: errorMessages.REGISTER_ERROR,
    defaultSuccessMessage: successMessages.ADD_USER,
    callback: getMemberErrorMessage,
  }
);
