import { ChangeEvent, FC, useMemo, useRef, useState } from 'react';
import { useAppSelector, useBreakPoint } from 'hooks';
import { IOption } from 'interfaces';
import { clansSelector } from 'store/slices/clan/selectors';

import { CollapseContainer } from 'components/CollapseContainer';
import { CopyIcon } from 'components/CopyIcon';
import { GeneratePassword } from 'components/GeneratePassword';
import { Button, Select, TextInput } from 'components/ui';
import { compareObjects, maskPassword } from 'utils';

import { TPlayerInfo } from '.';

interface BasicInfoProps {
  initialPlayerInfo: TPlayerInfo;
  playerInfo: TPlayerInfo;
  onChange: (info: TPlayerInfo) => void;
  onSave: () => Promise<void>;
  isAccessEditing: boolean;
  fieldErrors: { name: string; email: string; password: string };
  isNewPlayer?: boolean;
}

export const BasicInfo: FC<BasicInfoProps> = ({
  initialPlayerInfo,
  playerInfo,
  onChange,
  onSave,
  isAccessEditing,
  fieldErrors,
  isNewPlayer,
}) => {
  const { clans } = useAppSelector(clansSelector);

  const [isFocus, setFocus] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const isShowButtons =
    !isNewPlayer && !compareObjects(initialPlayerInfo, playerInfo);

  const breakPoint = useBreakPoint();

  const clanOptions = clans.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const handleChangeName = (value: string) => {
    onChange({ ...playerInfo, name: value });
  };

  const handleChangeLogin = (value: string) => {
    onChange({ ...playerInfo, email: value });
  };

  const handleChangePassword = (value: string) => {
    onChange({ ...playerInfo, password: value });
  };

  const handleGeneratePassword = (value: string) => {
    onChange({ ...playerInfo, password: value });
  };

  const handleSelectNativeClan = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      onChange({ ...playerInfo, originClan: value });
    }
  };

  const handleFocus = (_e: ChangeEvent<HTMLInputElement>) => {
    setFocus(true);
  };

  const handleBlur = (_e: ChangeEvent<HTMLInputElement>) => {
    setFocus(false);
  };

  const password = useMemo(
    () =>
      !isNewPlayer && !playerInfo.password && !isFocus
        ? maskPassword('password')
        : playerInfo.password,
    [playerInfo.password, isFocus]
  );

  const handleClickCancel = () => {
    onChange(initialPlayerInfo);
  };

  return !(breakPoint === 'mobile' || breakPoint === 'tablet') ? (
    <div className="w-6/12 border-r border-solid border-r-tpg_light overflow-auto min-h-full">
      <div className="bg-ultrablack px-[16px] pt-[16px] pb-[8px] sticky top-0 z-[3]">
        <span>Основная информация</span>
      </div>
      <div className="px-[16px] pt-[8px] pb-[16px] [&>div]last:border-b-none">
        <div className="flex flex-col border border-solid border-dark_product rounded-[10px]">
          <div className="flex flex-row items-center border-b border-solid border-b-dark_product">
            <div className="p-[12px] min-w-[195px]">
              <span className="tpg-c1">Позывной</span>
            </div>
            <div className="p-[12px] text-tpg_base tpg-c1 w-full border-l border-solid border-dark_product">
              {(isAccessEditing || isNewPlayer) && !playerInfo.banned ? (
                <TextInput
                  onChange={handleChangeName}
                  placeholder="Введите позывной кириллицей"
                  value={playerInfo.name}
                  inputClassName="bg-ultrablack !h-[19px] !pl-0 !text-tpg_base"
                  error={fieldErrors.name}
                />
              ) : (
                <span>{playerInfo.name}</span>
              )}
            </div>
          </div>
          {!isNewPlayer && (
            <div className="flex flex-row items-center border-b border-solid border-b-dark_product">
              <div className="p-[12px] min-w-[195px]">
                <span className="tpg-c1">Родное подразделение</span>
              </div>
              <div className="p-[12px] text-tpg_base tpg-c1 w-full border-l border-solid border-dark_product">
                {isAccessEditing && !playerInfo.banned ? (
                  <Select
                    value={playerInfo.originClan}
                    options={clanOptions}
                    onSelect={handleSelectNativeClan}
                    emptyOptionText="Не выбрано"
                    className="!pr-0 !min-h-[19px] w-full"
                  />
                ) : (
                  <span>{playerInfo.originClan.value || 'Не выбрано'}</span>
                )}
              </div>
            </div>
          )}
          <div className="flex flex-row items-center border-b border-solid border-b-dark_product">
            <div className="p-[12px] min-w-[195px]">
              <span className="tpg-c1">Логин</span>
            </div>
            <div className="p-[12px] text-tpg_base tpg-c1 w-full border-l border-solid border-dark_product">
              {(isAccessEditing || isNewPlayer) && !playerInfo.banned ? (
                <div className="flex flex-row items-start gap-[8px]">
                  <TextInput
                    value={playerInfo.email}
                    inputClassName="bg-ultrablack h-[19px] !pl-0 !text-tpg_base"
                    className="w-full"
                    placeholder="Введите логин латиницей"
                    onChange={handleChangeLogin}
                    error={fieldErrors.email}
                  />
                  <CopyIcon value={playerInfo.email} />
                </div>
              ) : (
                <span>{playerInfo.email}</span>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center">
            <div className="p-[12px] min-w-[195px]">
              <span className="tpg-c1">Пароль</span>
            </div>
            <div className="p-[12px] text-tpg_base tpg-c1 w-full border-l border-solid border-dark_product">
              <div className="flex flex-row justify-between items-start">
                {!isNewPlayer && (!isAccessEditing || playerInfo.banned) && (
                  <span className="tpg-c1 pointer-events-none">
                    {maskPassword('password')}
                  </span>
                )}
                {(isAccessEditing || isNewPlayer) && !playerInfo.banned && (
                  <>
                    <TextInput
                      onChange={handleChangePassword}
                      value={password || ''}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      placeholder="Сгенерируйте пароль"
                      inputClassName="bg-ultrablack h-[20px] !pl-0 !text-tpg_base"
                      error={fieldErrors.password}
                    />
                    <div className="flex flex-row gap-[8px]">
                      <GeneratePassword
                        onClick={handleGeneratePassword}
                        label="Сгенерировать"
                      />
                      {playerInfo.password && (
                        <CopyIcon value={playerInfo.password} />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowButtons && (
        <div className="flex flex-row gap-[12px] px-[16px]">
          <Button
            onClick={handleClickCancel}
            title="Отмена"
            className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
          />
          <Button onClick={onSave} title="Сохранить" className="w-full" />
        </div>
      )}
    </div>
  ) : (
    <div className="flex flex-col px-[16px] gap-[16px] pt-[16px]">
      <span>Основная информация</span>
      <div ref={containerRef}>
        <CollapseContainer
          hiddenBlock={
            <>
              <div className="min-h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
                <span className="tpg-c2 text-tpg_light">Пароль</span>
                <div className="flex flex-row justify-between items-start">
                  {!isNewPlayer && (!isAccessEditing || playerInfo.banned) && (
                    <span className="tpg-c1 pointer-events-none">
                      {maskPassword('password')}
                    </span>
                  )}
                  {(isAccessEditing || isNewPlayer) && !playerInfo.banned && (
                    <>
                      <TextInput
                        onChange={handleChangePassword}
                        value={password || ''}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        inputClassName="bg-ultrablack h-[20px] !pl-0"
                        error={fieldErrors.password}
                      />
                      <div className="flex flex-row gap-[8px]">
                        <GeneratePassword
                          onClick={handleGeneratePassword}
                          label="Сгенерировать"
                        />
                        {playerInfo.password && (
                          <CopyIcon value={playerInfo.password} />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          }
        >
          <div className="min-h-[61px] flex flex-col px-[16px] py-[10px]">
            <span className="tpg-c2 text-tpg_light">Позывной</span>
            {(isAccessEditing || isNewPlayer) && !playerInfo.banned ? (
              <TextInput
                onChange={handleChangeName}
                placeholder="Введите название"
                value={playerInfo.name}
                inputClassName="bg-ultrablack !h-[19px] !pl-0"
                error={fieldErrors.name}
              />
            ) : (
              <span>{playerInfo.name}</span>
            )}
          </div>
          <div className="min-h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
            <span className="tpg-c2 text-tpg_light">Логин</span>
            {(isAccessEditing || isNewPlayer) && !playerInfo.banned ? (
              <div className="flex flex-row items-start gap-[8px]">
                <TextInput
                  value={playerInfo.email}
                  inputClassName="bg-ultrablack h-[19px] !pl-0"
                  className="w-full"
                  placeholder="Введите значение"
                  onChange={handleChangeLogin}
                  error={fieldErrors.email}
                />
              </div>
            ) : (
              <div className="flex flex-col pl-[16px]">
                <span className="tpg-c2 text-tpg_light">
                  Родное подразделение
                </span>
                <span className="tpg-c1">
                  {playerInfo.originClan.value || 'Не выбрано'}
                </span>
              </div>
            )}
          </div>
        </CollapseContainer>
      </div>
      {isShowButtons && (
        <div className="flex flex-row gap-[12px]">
          <Button
            onClick={handleClickCancel}
            title="Отмена"
            className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
          />
          <Button onClick={onSave} title="Сохранить" className="w-full" />
        </div>
      )}
    </div>
  );
};
