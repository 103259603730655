import { createAsyncThunk } from '@reduxjs/toolkit';
import { getClanRoomsAdapter, roomAdapter } from 'api/helpers/roomAdapter';
import {
  createRoom,
  createRoomIngress,
  deleteRoom,
  deleteRoomIngress,
  getAllLiveKitTokens,
  getAllRooms,
  getClanRooms,
  getLiveKitToken,
  getRecordingSignal,
} from 'api/room';
import {
  IClanRoomsAdapted,
  ICreateRoomChainResponse,
  IDeleteRoomInput,
  IGetAllLiveKitTokens,
  IGetLiveKitTokenInput,
  ILiveKitTokenResponse,
  ISignalRoomInput,
  ITokenResponse,
  TCreateRoomInput,
} from 'api/types/room';
import { errorMessages } from 'constants/messages';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import { IRoom } from 'interfaces';
import { groupBy } from 'lodash';

import { createThunk } from 'utils';

import { roomActions } from '.';

export const getClanRoomsThunk = createAsyncThunk<IClanRoomsAdapted, string>(
  `${reducersNames.ROOM}/${asyncActionsNames.GET_CLANS_ROOMS}`,
  async (request, thunkAPI) => {
    const { data, error } = await getClanRooms(request);

    if (data) {
      return getClanRoomsAdapter(data);
    }

    return thunkAPI.rejectWithValue(error);
  }
);

export const getAllRoomsThunk = createAsyncThunk<IClanRoomsAdapted[], void>(
  `${reducersNames.ROOM}/${asyncActionsNames.GET_ALL_ROOMS}`,
  async (_request, thunkAPI) => {
    const { data, error } = await getAllRooms();

    if (data) {
      const groupedByClan = groupBy(data, (d) => d.clan_id);

      const result: IClanRoomsAdapted[] = Object.entries(groupedByClan).map(
        ([k, v]) => ({
          clanId: k,
          rooms: v.map(roomAdapter),
        })
      );

      return result;
    }

    return thunkAPI.rejectWithValue(error);
  }
);

export const getLiveKitTokenThunk = createAsyncThunk<
  ITokenResponse,
  IGetLiveKitTokenInput
>(
  `${reducersNames.ROOM}/${asyncActionsNames.GET_STREAM_TOKEN}`,
  async (request, thunkAPI) => {
    const { clanId, roomName } = request;

    const { data, error } = await getLiveKitToken({ clanId, roomName });

    if (data) {
      return { ...data, clanId, roomName };
    }

    return thunkAPI.rejectWithValue(error);
  }
);

export const getAllLiveKitTokensThunk = createThunk<
  { items: ILiveKitTokenResponse[] },
  IGetAllLiveKitTokens[]
>(
  `${reducersNames.ROOM}/${asyncActionsNames.GET_ALL_STREAM_TOKENS}`,
  getAllLiveKitTokens
);

export const createRoomIngressThunk = createAsyncThunk<any, TCreateRoomInput>(
  `${reducersNames.ROOM}/${asyncActionsNames.CREATE_ROOM_INGRESS}`,
  async (request, thunkAPI) => {
    const { clanId, roomName } = request;

    try {
      const data = thunkAPI
        .dispatch(
          createRoomThunk({
            roomName,
            clanId,
          })
        )
        .then(async ({ payload }) => {
          if (payload && typeof payload === 'object' && 'token' in payload) {
            const { data, error } = await createRoomIngress({
              clanId,
              roomName,
            });

            if (data) {
              thunkAPI.dispatch(
                getAllLiveKitTokensThunk([
                  {
                    clan_id: clanId,
                    room_name: roomName,
                  },
                ])
              );

              return data;
            }

            thunkAPI.dispatch(
              deleteRoomThunk({
                clanId,
                roomName,
                params: { save_recording: false },
              })
            );

            thunkAPI.dispatch(roomActions.setMethod(null));

            return thunkAPI.rejectWithValue({
              error,
              message: errorMessages.START_RECORDING,
            });
          }

          thunkAPI.dispatch(
            deleteRoomThunk({
              clanId,
              roomName,
              params: { save_recording: false },
            })
          );
        })
        .catch(async (error) =>
          thunkAPI.rejectWithValue({
            error,
            message: errorMessages.CREATE_ROOM,
          })
        );

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createRoomThunk = createAsyncThunk<
  ICreateRoomChainResponse,
  TCreateRoomInput
>(
  `${reducersNames.ROOM}/${asyncActionsNames.CREATE_ROOM}`,
  async (request, thunkAPI) => {
    const { clanId, roomName } = request;

    const { data, error } = await createRoom({ roomName, clanId });

    if (data) {
      const {
        livekitName,
        numParticipants,
        accountId,
        isRecording,
        src,
        isActive,
        createdAt,
      } = roomAdapter(data);

      const { data: livekitData } = await getLiveKitToken({ clanId, roomName });

      if (livekitData) {
        return {
          livekitName,
          roomName,
          numParticipants,
          accountId,
          token: livekitData.token,
          isRecording,
          src,
          isActive,
          createdAt,
        };
      }
    }

    return thunkAPI.rejectWithValue(error);
  }
);

export const getRecordingSignalThunk = createAsyncThunk<
  IRoom & { clanId: string; roomName: string },
  ISignalRoomInput
>(
  `${reducersNames.ROOM}/${asyncActionsNames.GET_RECORDING_SIGNAL}`,
  async (request, thunkAPI) => {
    const { roomName, clanId } = request;

    const { data, error } = await getRecordingSignal({ roomName, clanId });

    if (data) {
      return { ...roomAdapter(data), roomName, clanId };
    }

    return thunkAPI.rejectWithValue(error);
  }
);

export const deleteRoomThunk = createThunk<void, IDeleteRoomInput>(
  `${reducersNames.ROOM}/${asyncActionsNames.DELETE_ROOM}`,
  deleteRoom
);

export const deleteRoomIngressThunk = createThunk<
  void,
  Omit<IDeleteRoomInput, 'params'>
>(
  `${reducersNames.ROOM}/${asyncActionsNames.DELETE_ROOM_INGRESS}`,
  deleteRoomIngress
);
