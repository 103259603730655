import axios from 'services/axios';

import { requestHandler } from './utils/requestHandler';

export const getClanRecords = requestHandler(
  ({ clanId, params }: { clanId: string; params?: Record<string, any> }) =>
    axios.get(`/clans/${clanId}/recordings`, {
      params,
    })
);

export const getRecord = requestHandler((recordId: string) =>
  axios.get(`/recordings/${recordId}`)
);
