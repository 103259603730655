import { ElementType, FC, ReactNode, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { EUserRole, roleWeights } from 'constants/profile';
import {
  appRoutes,
  authRoutes,
  DEPARTMENT_PREFIX,
  navigation,
} from 'constants/routes';
import { useAppDispatch, useAppSelector, useBreakPoint } from 'hooks';
import { MainLayout } from 'layouts/MainLayout';
import { ClanAdminPanelScreen } from 'routes/ClanAdminPanel';
import { ClansScreen } from 'routes/Clans';
import { HeaderNavigation } from 'routes/Clans/HeaderNavigation';
import { DepartmentsTable } from 'routes/DepartmentsTable';
import { SearchByDepartmentsTable } from 'routes/DepartmentsTable/SearchByDepartmentsTable';
import { LoggedInScreen } from 'routes/LoggedIn';
import { Login } from 'routes/Login';
import { PlayerAdminPanelScreen } from 'routes/PlayerAdminPanel';
import { RecordScreen } from 'routes/Record';
import { RecordsScreen } from 'routes/RecordsScreen';
import { StreamScreen } from 'routes/Stream';
import { StreamLaunchScreen } from 'routes/StreamLaunch';
import { StreamLaunchAppScreen } from 'routes/StreamLaunchApp';
import { StreamsPanelScreen } from 'routes/StreamsPanel';
import { FilterStreamsButton } from 'routes/StreamsPanel/FilterStreamsButton';
import { ToggleMetaInfo } from 'routes/StreamsPanel/ToggleMetaInfo';
import { UsersTable } from 'routes/UsersTable';
import { SearchByUsersTable } from 'routes/UsersTable/SearchByUsersTable';
import { UserStreamsLayout } from 'routes/UserStreamsLayout';
import { authActions, authSelector, getAccountMeThunk } from 'store';
import { roomSelector } from 'store/slices/room/selectors';

import { AddButton } from 'components/AddButton';
import { BackButton } from 'components/BackButton';
import { FullScreenButton } from 'components/FullScreenButton';
import { NotFound } from 'components/NotFound';
import { PinnedStreams } from 'components/PinnedStreams';
import { TwoFactorModal } from 'components/TwoFactorModal';
import { Loader } from 'components/ui/Loader';
import { getRefreshToken, getToken } from 'utils';

type TRouteProps = {
  path: string;
  component: ReactNode;
  layout: ElementType | null;
  rightHeaderButton?: ReactNode;
  leftContent?: ReactNode;
  centralContent?: ReactNode;
  сontentPanelMenu?: ReactNode;
  isPageControlPanel?: boolean;
  isShowHeader?: boolean;
  isStartStream?: boolean;
  resetOptions?: () => void;
};

const authPages = Object.values(authRoutes);

const publicPages = Object.values({
  ...appRoutes,
});

const getProperRedirect = (path: string) => {
  const { role } = useAppSelector(authSelector);

  const accessToken = getToken();

  if (!accessToken && publicPages.includes(path)) {
    return <Navigate to={authRoutes.SIGN_IN} replace />;
  }

  if (accessToken && authPages.includes(path)) {
    return <Navigate to={role ? navigation[role] : '/'} replace />;
  }

  return null;
};

const AppRoute: FC<TRouteProps> = ({
  component,
  layout,
  path,
  rightHeaderButton,
  leftContent,
  centralContent,
  сontentPanelMenu,
  isPageControlPanel,
  isShowHeader,
  isStartStream,
  resetOptions,
}) => {
  const redirect = getProperRedirect(path);

  if (redirect) return redirect;

  if (layout) {
    const Layout = layout;

    return (
      <Layout
        rightHeaderButton={rightHeaderButton && rightHeaderButton}
        centralContent={centralContent && centralContent}
        leftContent={leftContent && leftContent}
        сontentPanelMenu={сontentPanelMenu && сontentPanelMenu}
        isPageControlPanel={isPageControlPanel}
        isShowHeader={isShowHeader}
        isStartStream={isStartStream}
        resetOptions={resetOptions}
      >
        {component}
      </Layout>
    );
  }

  return <>{component}</>;
};

export const App: FC = (): JSX.Element => {
  const [isFullscreenOpen, setFullscreenOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isTwoFactorModalOpen, setTwoFactorModalOpen] = useState(false);

  const { method } = useAppSelector(roomSelector);

  const {
    role,
    id: accountId,
    mfaRequired,
    mfaState,
  } = useAppSelector(authSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const accessToken = getToken();
  const refreshToken = getRefreshToken();

  useEffect(() => {
    if (mfaRequired && mfaState && !accessToken) {
      setTwoFactorModalOpen(true);
    }
  }, [mfaRequired, mfaState, accessToken]);

  useEffect(() => {
    if ((!accountId || !role) && refreshToken) {
      dispatch(getAccountMeThunk());
    }

    if (!accessToken && !refreshToken) {
      dispatch(authActions.logout());
    }
  }, [accessToken, refreshToken]);

  const handleFullscreenOpen = (value: boolean) => {
    setFullscreenOpen(value);
  };

  const handleClickFilter = (value: boolean) => {
    setFilterOpen(value);
  };

  const handleResetOptions = () => {
    setFilterOpen(false);
    setFullscreenOpen(false);
  };

  const breakPoint = useBreakPoint();

  const isMobile = breakPoint === 'mobile';

  if (isTwoFactorModalOpen) {
    return <TwoFactorModal onClose={() => setTwoFactorModalOpen(false)} />;
  }

  if (!role) {
    return (
      <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path={authRoutes.SIGN_IN}
        element={
          <AppRoute
            path={authRoutes.SIGN_IN}
            component={<Login />}
            layout={null}
          />
        }
      />
      <Route path={authRoutes.LOGGED_IN} element={<LoggedInScreen />} />
      {roleWeights[role] >= roleWeights.admin && (
        <>
          <Route
            path={appRoutes.ADMIN_NEW_USER}
            element={
              <AppRoute
                path={appRoutes.ADMIN_NEW_USER}
                component={<PlayerAdminPanelScreen isNewPlayer />}
                layout={MainLayout}
                leftContent={<BackButton />}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN_DEPARTMENT}
            element={
              <AppRoute
                path={appRoutes.ADMIN_DEPARTMENT}
                component={<ClanAdminPanelScreen />}
                layout={MainLayout}
                rightHeaderButton={role === EUserRole.ROOT || <div />}
                leftContent={<BackButton path={appRoutes.ADMIN_DEPARTMENTS} />}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN_USER}
            element={
              <AppRoute
                path={appRoutes.ADMIN_USER}
                component={<PlayerAdminPanelScreen />}
                layout={MainLayout}
                rightHeaderButton={role === EUserRole.ROOT || <div />}
                leftContent={<BackButton />}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN_NEW_DEPARTMENT}
            element={
              <AppRoute
                path={appRoutes.ADMIN_NEW_DEPARTMENT}
                component={<ClanAdminPanelScreen isNewClan />}
                layout={MainLayout}
                leftContent={<BackButton />}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN_USERS}
            element={
              <AppRoute
                path={appRoutes.ADMIN_USERS}
                component={<UsersTable />}
                layout={MainLayout}
                rightHeaderButton={
                  <AddButton
                    label="Добавить пользователя"
                    onClick={() => navigate(`${appRoutes.ADMIN_NEW_USER}`)}
                    className={
                      breakPoint === 'mobile'
                        ? 'text-tpg_base !justify-start'
                        : 'flex-row-reverse'
                    }
                    isShowIcon={breakPoint !== 'mobile'}
                  />
                }
                leftContent={<SearchByUsersTable />}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN_DEPARTMENTS}
            element={
              <AppRoute
                path={appRoutes.ADMIN_DEPARTMENTS}
                component={<DepartmentsTable />}
                layout={MainLayout}
                rightHeaderButton={
                  <AddButton
                    label="Добавить подразделение"
                    onClick={() =>
                      navigate(`${appRoutes.ADMIN_NEW_DEPARTMENT}`)
                    }
                    className={
                      breakPoint === 'mobile'
                        ? 'text-tpg_base !justify-start'
                        : 'flex-row-reverse'
                    }
                    isShowIcon={breakPoint !== 'mobile'}
                  />
                }
                leftContent={<SearchByDepartmentsTable />}
              />
            }
          />
        </>
      )}
      {roleWeights[role] >= roleWeights.moderator && (
        <>
          <Route
            path={appRoutes.ADMIN_DEPARTMENT_RECORDS}
            element={
              <AppRoute
                path={appRoutes.ADMIN_DEPARTMENT_RECORDS}
                component={<RecordsScreen />}
                rightHeaderButton={<BackButton />}
                layout={MainLayout}
              />
            }
          />
          {/* ToDo: temporarily hidden */}
          {/* <Route
            path={appRoutes.MAP}
            element={
              <AppRoute
                path={appRoutes.MAP}
                component={<MapScreen />}
                layout={MainLayout}
                isPageControlPanel={false}
              />
            }
          /> */}
        </>
      )}
      {role === EUserRole.USER && (
        <Route
          path={DEPARTMENT_PREFIX}
          element={
            <AppRoute
              path={DEPARTMENT_PREFIX}
              component={<UserStreamsLayout />}
              layout={MainLayout}
              centralContent={<HeaderNavigation />}
            />
          }
        />
      )}
      <Route
        path={appRoutes.RECORDS_DEPARTMENT}
        element={
          <AppRoute
            path={appRoutes.RECORDS_DEPARTMENT}
            component={<RecordsScreen />}
            layout={MainLayout}
            leftContent={<BackButton path="/records" />}
            rightHeaderButton={
              role === EUserRole.USER || role === EUserRole.ROOT || <div />
            }
            centralContent={
              !(isMobile || breakPoint === 'tablet') && <HeaderNavigation />
            }
            isShowHeader={!isFullscreenOpen}
            isStartStream={role === EUserRole.USER || role === EUserRole.ROOT}
            resetOptions={handleResetOptions}
          />
        }
      />
      <Route
        path={appRoutes.RECORDS}
        element={
          <AppRoute
            path={appRoutes.RECORDS}
            component={<ClansScreen />}
            layout={MainLayout}
            rightHeaderButton={true}
            isStartStream={role === EUserRole.USER || role === EUserRole.ROOT}
          />
        }
      />
      <Route
        path={appRoutes.STREAMS}
        element={
          <AppRoute
            path={appRoutes.STREAMS}
            component={
              (method === 'browser' && <StreamLaunchScreen />) ||
              (method === 'drone' && <StreamLaunchAppScreen />) || (
                <StreamsPanelScreen
                  onFullscreenClose={handleFullscreenOpen}
                  isFullscreenOpen={isFullscreenOpen}
                />
              )
            }
            layout={MainLayout}
            rightHeaderButton={
              !method && (
                <div
                  className={cn('flex flex-row gap-[16px]', {
                    'flex-col !gap-0 justify-start': isMobile,
                  })}
                >
                  {isMobile && (
                    <FilterStreamsButton
                      isMobile={isMobile}
                      isFilterOpen={isFilterOpen}
                      onClick={handleClickFilter}
                    />
                  )}
                  <FullScreenButton
                    onClick={handleFullscreenOpen}
                    isShowIcon={!isMobile}
                    className={isMobile ? 'text-tpg_base !justify-start' : ''}
                  />
                </div>
              )
            }
            leftContent={
              <>
                {!method && !isMobile && (
                  <div
                    className={cn('flex flex-row items-center gap-[24px]', {
                      '!gap-[4px]': breakPoint === 'tablet',
                    })}
                  >
                    <FilterStreamsButton
                      isMobile={isMobile}
                      isFilterOpen={isFilterOpen}
                      onClick={handleClickFilter}
                    />
                    <PinnedStreams />
                  </div>
                )}
                {!method && isMobile && <PinnedStreams />}
              </>
            }
            сontentPanelMenu={<ToggleMetaInfo />}
            isShowHeader={!isFullscreenOpen}
            isStartStream={
              !method && (role === EUserRole.USER || role === EUserRole.ROOT)
            }
            resetOptions={handleResetOptions}
          />
        }
      />
      <Route
        path={appRoutes.RECORD}
        element={
          <AppRoute
            path={appRoutes.RECORD}
            component={<RecordScreen />}
            layout={MainLayout}
            leftContent={<BackButton />}
            rightHeaderButton={<div />}
          />
        }
      />
      <Route
        path={appRoutes.STREAM}
        element={
          <AppRoute
            path={appRoutes.STREAM}
            component={<StreamScreen />}
            layout={MainLayout}
            rightHeaderButton={<BackButton />}
          />
        }
      />
      <Route path="/" element={<Navigate replace to={navigation[role]} />} />
      <Route
        path="*"
        element={
          role ? <Navigate replace to={navigation[role]} /> : <NotFound />
        }
      />
    </Routes>
  );
};
