import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAccountMe } from 'api/account';
import { loggedIn, login } from 'api/auth';
import { checkMfaParams, updateMfaRequired } from 'api/mfa';
import { getRoles } from 'api/role';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import {
  ILoggedInRequest,
  ILoggedInResponse,
  ILoginResponse,
  IMfaParams,
  IMfaRequiredParams,
} from 'interfaces';
import { IPlayerResponse } from 'interfaces/player';

import { createThunk } from 'utils';
import { setLStorageMany } from 'utils/helpers/localStorage';

export const loginThunk = createThunk<ILoginResponse>(
  `${reducersNames.AUTH}/${asyncActionsNames.LOGIN}`,
  login
);

export const loggedInThunk = createAsyncThunk<
  ILoggedInResponse,
  ILoggedInRequest
>(
  `${reducersNames.LOGGED_IN}/${asyncActionsNames.LOGIN}`,
  async (request, thunkAPI) => {
    const { data, error } = await loggedIn(request);

    if (data) {
      const { refresh_token, token, mfa_required, mfa_state } = data;

      if (mfa_required && mfa_state) {
        return data;
      }

      setLStorageMany({
        refresh_token,
        token,
      });

      await thunkAPI.dispatch(getAccountMeThunk());

      return data;
    }

    return thunkAPI.rejectWithValue(error);
  }
);

export const checkMfaParamsThunk = createAsyncThunk<
  ILoggedInResponse,
  IMfaParams
>(
  `${reducersNames.LOGGED_IN}/${asyncActionsNames.CHECK_MFA_PARAMS}`,
  async (request, thunkAPI) => {
    const { data, error } = await checkMfaParams(request);

    if (data) {
      const { refresh_token, token } = data;

      setLStorageMany({
        refresh_token,
        token,
      });

      await thunkAPI.dispatch(getAccountMeThunk());

      return data;
    }

    return thunkAPI.rejectWithValue(error);
  }
);

export const getRolesThunk = createThunk(
  `${reducersNames.AUTH}/${asyncActionsNames.GET_ROLES}`,
  getRoles
);

export const getAccountMeThunk = createThunk<IPlayerResponse, void>(
  `${reducersNames.AUTH}/${asyncActionsNames.GET_ACCOUNT_ME}`,
  getAccountMe
);

export const updateMfaRequiredThunk = createAsyncThunk<
  Pick<IMfaRequiredParams, 'mfa_required'>,
  IMfaRequiredParams
>(
  `${reducersNames.AUTH}/${asyncActionsNames.UPDATE_MFA_REQUIRED}`,
  async (request) => {
    const { data } = await updateMfaRequired(request);

    if (data) {
      return { ...data, mfa_required: request.mfa_required };
    }
  }
);
