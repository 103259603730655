import { FC } from 'react';
import { useAppSelector } from 'hooks';
import { roomSelector } from 'store/slices/room/selectors';

import { FilterStreams } from 'components/FilterStreams';
import { FilterButton } from 'components/ui/FilterButton';

interface FilterStreamsButtonProps {
  isFilterOpen: boolean;
  isMobile: boolean;
  onClick: (value: boolean) => void;
}

export const FilterStreamsButton: FC<FilterStreamsButtonProps> = ({
  isFilterOpen,
  isMobile,
  onClick,
}) => {
  const { treeRooms } = useAppSelector(roomSelector);

  const activeClans = treeRooms.filter(({ rooms }) =>
    rooms.some(({ isHidden }) => !isHidden)
  );

  const hiddenRooms = treeRooms.flatMap(({ rooms }) =>
    rooms.filter(({ isHidden }) => isHidden)
  );

  return (
    <FilterButton
      label={`Подразделения (${activeClans.length} из ${treeRooms.length})`}
      onClick={onClick}
      isOpen={isFilterOpen}
      isActive={!!hiddenRooms.length && !!activeClans.length}
      content={<FilterStreams />}
      topPosition={isMobile ? '106px' : '45px'}
      rightPosition={isMobile ? '-45px' : 'none'}
      className={isMobile ? 'text-tpg_base' : ''}
      isShowIcon={!isMobile}
    />
  );
};
