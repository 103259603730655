import { ValueOf } from 'types';

/**
 * Restrictions on the number of characters that can be entered in the user name field
 */
export const USER_NAME_LIMIT = 100;

/**
 * Restrictions on the number of characters that can be entered in the password field
 */
export const PASSWORD_LIMIT = 20;

export const LP_INTERVAL_MS = 20000;

export enum EUserRole {
  USER = 'user',
  MODERATOR = 'moderator',
  ADMIN = 'admin',
  ROOT = 'root',
}

export enum EUserRoleTitle {
  USER = 'Источник',
  MODERATOR = 'Потребитель',
  ADMIN = 'Организатор',
  ROOT = 'Администратор',
}

export const roleID = {
  [EUserRole.ADMIN]: '00000000000000000000000006',
  [EUserRole.MODERATOR]: '00000000000000000000000005',
  [EUserRole.USER]: '00000000000000000000000004',
};

export const featurePermission: Record<string, ValueOf<EUserRole>[]> = {
  'create-report': [EUserRole.MODERATOR],
  'create-client': [EUserRole.MODERATOR],
  'feature-filter-by-client': [EUserRole.MODERATOR],
  'converter-customer-reports': [EUserRole.MODERATOR],
  'delete-imagery': [EUserRole.MODERATOR],
};

export const roleWeights: {
  [key in EUserRole]: number;
} = {
  root: 3,
  admin: 2,
  moderator: 1,
  user: 0,
} as const;
