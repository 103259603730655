import { FC, memo, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LP_INTERVAL_MS } from 'constants/profile';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IGroupRooms, ITreeRooms } from 'interfaces';
import { authSelector } from 'store/slices/auth';
import { clansSelector } from 'store/slices/clan';
import { roomActions } from 'store/slices/room';
import { getAllRoomsThunk } from 'store/slices/room/actions';
import { rolesValuesMap } from 'types/player';

import { setHiddenRoomsInSearchParams } from 'utils';

import { Panel } from './Panel';

interface AllClansStreamsPanelProps {
  isFullscreenOpen: boolean;
  rooms: Map<string, IGroupRooms>;
  treeRooms: ITreeRooms[];
  onBack: () => void;
}

export const AllClansStreamsPanel: FC<AllClansStreamsPanelProps> = memo(
  ({ isFullscreenOpen, rooms: roomsData, treeRooms, onBack }) => {
    const { clans } = useAppSelector(clansSelector);

    const { id: accountId, roles } = useAppSelector(authSelector);

    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useAppDispatch();

    const rooms = useMemo(
      () =>
        treeRooms.flatMap(
          (data) =>
            data.rooms?.map((room) => ({
              ...room,
              clanName: data.clanName,
              clanId: data.clanId,
            })) || []
        ),
      [treeRooms]
    );

    const filteredRooms = useMemo(
      () =>
        rooms
          .filter(
            (room) =>
              room.accountId === accountId ||
              roles.some(
                (role) =>
                  role.clan_id === room.clanId &&
                  role.role_id !== rolesValuesMap.user
              )
          )
          .sort((a, b) =>
            a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0
          ),
      [rooms, accountId, roles]
    );

    useEffect(() => {
      const getRooms = () => {
        if (clans) {
          dispatch(getAllRoomsThunk());
        }
      };

      getRooms();

      const roomsInterval = setInterval(getRooms, LP_INTERVAL_MS);

      return () => {
        clearInterval(roomsInterval);
      };
    }, [clans]);

    useEffect(() => {
      dispatch(roomActions.setTreeRooms({ clans, searchParams }));
    }, [roomsData, clans, searchParams]);

    useEffect(() => {
      if (!searchParams.size) {
        const params = setHiddenRoomsInSearchParams(searchParams, treeRooms);

        setSearchParams(params);
      }
    }, [treeRooms, searchParams]);

    console.log(`Rooms re-rendered: ${filteredRooms.length}`);

    return (
      <Panel
        isFullscreenOpen={isFullscreenOpen}
        onBack={onBack}
        rooms={filteredRooms}
      />
    );
  }
);

AllClansStreamsPanel.displayName = 'AllClansStreamsPanel';
