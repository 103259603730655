import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useAppDispatch, useAppSelector, useBreakPoint } from 'hooks';
import { ReactComponent as Close } from 'images/newIcons/close.svg';
import { ITreeRooms } from 'interfaces/room';
import { roomActions, roomSelector } from 'store/slices/room';

import { TextInput } from 'components/ui';
import {
  allRoomsHideController,
  filterTreeRoomsBySearch,
  getWordDayForm,
} from 'utils';

import { ListClans } from './ListClans';

export const FilterStreams = () => {
  const [searchValue, setSearchValue] = useState('');
  const [treeRooms, setTreeRooms] = useState<ITreeRooms[]>([]);
  const [isAllActiveStreamsHidden, setAllActiveStreamsHidden] = useState(false);

  const [isAllInactiveStreamsHidden, setAllInactiveStreamsHidden] =
    useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const { treeRooms: rooms } = useAppSelector(roomSelector);

  const dispatch = useAppDispatch();

  const breakPoint = useBreakPoint();

  useEffect(
    () => () => {
      setSearchValue('');
    },
    []
  );

  useEffect(() => {
    if (searchValue) {
      const filteredTreeRooms = filterTreeRoomsBySearch(rooms, searchValue);

      setTreeRooms(filteredTreeRooms);
    } else {
      setTreeRooms(rooms);
    }
  }, [rooms, searchValue]);

  const activeClans = treeRooms.filter(({ rooms }) => rooms.length);

  const inactiveClans = treeRooms.filter(({ rooms }) => !rooms.length);

  useEffect(() => {
    const isHiddenRooms = activeClans.filter(({ rooms }) =>
      rooms.some(({ isHidden }) => isHidden)
    );

    if (isHiddenRooms.length) {
      setAllActiveStreamsHidden(true);
    } else {
      setAllActiveStreamsHidden(false);
    }
  }, [activeClans]);

  useEffect(() => {
    const isHiddenRooms = inactiveClans.filter(({ isHidden }) => isHidden);

    if (isHiddenRooms.length) {
      setAllInactiveStreamsHidden(true);
    } else {
      setAllInactiveStreamsHidden(false);
    }
  }, [inactiveClans]);

  const allRooms = treeRooms.flatMap(({ rooms }) => rooms);

  const activeRooms = allRooms.filter(({ isHidden }) => !isHidden);

  const streamWordForm = getWordDayForm(activeRooms.length, [
    'эфир',
    'эфира',
    'эфиров',
  ]);

  const handleChangeSearch = (value: string) => {
    const filteredTreeRooms = filterTreeRoomsBySearch(rooms, value);

    setTreeRooms(filteredTreeRooms);

    setSearchValue(value);
  };

  const onFiltersClear = () => {
    const updatedTreeRooms = allRoomsHideController(treeRooms, rooms, true);

    dispatch(roomActions.updateTreeRooms(updatedTreeRooms));

    searchParams.delete('showClans');
    searchParams.delete('showAllClans');

    setSearchParams(searchParams);
  };

  const handleAllActiveStreamsHide = (value: boolean) => {
    setAllActiveStreamsHidden(value);
  };

  const handleAllInactiveStreamsHide = (value: boolean) => {
    setAllInactiveStreamsHidden(value);
  };

  return (
    <div>
      <TextInput
        onChange={handleChangeSearch}
        value={searchValue}
        placeholder="Введите источник или подразделение"
        searchIcon
        inputClassName="!rounded-t-[10px] !rounded-b-none !py-[9px] !pr-[12px] !pl-[28px] !border-none"
        className="[&>div>svg]:!ml-[6px] [&>div>svg]:!w-5 [&>div>svg]:!h-5"
        size="m"
      />
      <div
        className={cn(
          'max-h-[496px] overflow-auto border-y border-solid border-tpg_light bg-ligh',
          {
            'max-h-[450px]': breakPoint === 'desktop',
            'max-h-[367px]':
              breakPoint === 'mobile' ||
              breakPoint === 'tablet' ||
              breakPoint === 'tablet-landscape',
          }
        )}
      >
        <div className="flex flex-col px-[16px] pt-[8px] gap-[5px] pb-[5px]">
          {activeClans.length || inactiveClans.length ? (
            <ListClans
              activeClans={activeClans}
              inactiveClans={inactiveClans}
              treeRooms={treeRooms}
              isAllActiveStreamsHidden={isAllActiveStreamsHidden}
              isAllInactiveStreamsHidden={isAllInactiveStreamsHidden}
              onAllActiveStreamsHide={handleAllActiveStreamsHide}
              onAllInactiveStreamsHide={handleAllInactiveStreamsHide}
            />
          ) : (
            <span className="tpg-c2 text-tpg_base">0 в эфире</span>
          )}
        </div>
      </div>
      <div className="px-[12px] py-[6px] flex items-center justify-between bg-light rounded-b-[10px]">
        <span className="tpg-c2 text-tpg_base">{`${activeRooms.length} ${streamWordForm} из ${allRooms.length}`}</span>
        <div
          className="flex flex-row items-center gap-1 cursor-pointer text-tpg_base hover:text-bright_product transition"
          onClick={onFiltersClear}
        >
          <span className="tpg-c2">Сбросить все</span>
          <Close className="w-[20px] h-[20px] [&>path]:fill-bright_product" />
        </div>
      </div>
    </div>
  );
};
