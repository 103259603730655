import { EUserRole, EUserRoleTitle } from 'constants/profile';
import { IPlayer } from 'interfaces/player';

import { ISelectOption } from '../interfaces';

export const rolesTranslateMap = {
  [EUserRole.ADMIN]: 'Организатор',
  [EUserRole.MODERATOR]: 'Потребитель',
  [EUserRole.USER]: 'Источник',
  [EUserRole.ROOT]: 'Администратор',
};

export const rolesValuesMap = {
  [EUserRole.ADMIN]: '00000000000000000000000006',
  [EUserRole.MODERATOR]: '00000000000000000000000005',
  [EUserRole.USER]: '00000000000000000000000004',
  [EUserRole.ROOT]: '00000000000000000000000001',
};

export const rolesReverseMap: { [key: string]: EUserRole } = {
  ['00000000000000000000000006']: EUserRole.ADMIN,
  ['00000000000000000000000005']: EUserRole.MODERATOR,
  ['00000000000000000000000004']: EUserRole.USER,
  ['00000000000000000000000001']: EUserRole.ROOT,
};

export type TPlayerTable = Omit<IPlayer, 'login' | 'password'>;

export const ROLES_SELECT_OPTIONS: ISelectOption[] = [
  { value: EUserRole.ADMIN, label: EUserRoleTitle.ADMIN },
  { value: EUserRole.ROOT, label: EUserRoleTitle.ROOT },
  { value: EUserRole.USER, label: EUserRoleTitle.USER },
  { value: EUserRole.MODERATOR, label: EUserRoleTitle.MODERATOR },
];
