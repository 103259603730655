import { FC, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { HEADER_HEIGHT, PADDING } from 'constants/common';
import {
  useAppDispatch,
  useBreakPoint,
  useClickOutside,
  useWindowSize,
} from 'hooks';
import { IRoomParams, ITreeRooms } from 'interfaces';
import { getAllLiveKitTokensThunk } from 'store/slices/room/actions';

import { Room } from 'components/Room';
import { convertRoomNameToParams } from 'utils';

import { RoomList } from './RoomList';

interface MainRoomPanelProps {
  isFullscreenOpen: boolean;
  mainRoom: IRoomParams;
  rooms: IRoomParams[];
  onSelectMainRoom: (name: string) => void;
  clanId?: string;
}

const convertToRoomTree = (rooms: IRoomParams[]) =>
  rooms.reduce((treeRooms: ITreeRooms[], room) => {
    const existingClan = treeRooms.find((data) => data?.clanId === room.clanId);

    if (existingClan) {
      existingClan.rooms.push(room);
    } else {
      room.clanName &&
        room.clanId &&
        treeRooms.push({
          clanName: room.clanName,
          clanId: room.clanId,
          rooms: [room],
          isHidden: false,
          isPinned: false,
        });
    }

    return treeRooms;
  }, []);

const GAP = 8;
const MIN_HEIGHT = 150;
const PERCENT = 15;

export const MainRoomPanel: FC<MainRoomPanelProps> = ({
  isFullscreenOpen,
  mainRoom,
  rooms,
  onSelectMainRoom,
  clanId: id,
}) => {
  const [isOpenList, setOpenList] = useState(false);
  const [playerHeight, setPlayerHeight] = useState(0);
  const [panelHeight, setPanelHeight] = useState(0);

  const [isResizing, setResizing] = useState(false);
  const [mousePositionY, setMousePositionY] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [dragStartHeight, setDragStartHeight] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(0);

  const { height } = useWindowSize();

  const containerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const breakPoint = useBreakPoint();

  useClickOutside<HTMLDivElement, void>(containerRef, () => setOpenList(false));

  const {
    livekitName,
    publisherName,
    token,
    roomName,
    accountId,
    clanName,
    clanId,
  } = mainRoom;

  const filteredRoom = useMemo(
    () => rooms.filter((room) => room.livekitName !== livekitName),
    [rooms, livekitName]
  );

  const count =
    (breakPoint === 'desktop' && filteredRoom.length > 4 && 4) ||
    (breakPoint === 'tablet-landscape' && filteredRoom.length > 3 && 3) ||
    ((breakPoint === 'tablet' || breakPoint === 'mobile') && 1) ||
    (filteredRoom.length > 5 && 5) ||
    filteredRoom.length;

  const roomList =
    filteredRoom.length > count
      ? filteredRoom.slice(0, count - 1)
      : filteredRoom;

  useEffect(() => {
    const heightScreen = isFullscreenOpen ? height : height - HEADER_HEIGHT;

    if (height) {
      const bottomPanelHeight = Math.ceil((heightScreen * PERCENT) / 100);

      const difference = heightScreen - bottomPanelHeight - PADDING - GAP;

      setPlayerHeight(difference);
      setMaxHeight(difference);
      setDragStartHeight(difference);
      setViewportHeight(heightScreen);
      setPanelHeight(bottomPanelHeight);
    }
  }, [height, isFullscreenOpen]);

  useEffect(() => {
    const roomsWithoutToken = roomList.filter(({ token }) => !token);

    if (roomsWithoutToken.length) {
      const params = roomsWithoutToken.map((room) => {
        const { clan_id, room_name } = convertRoomNameToParams(
          room.livekitName
        );

        return {
          clan_id,
          room_name,
        };
      });

      if (params.length) {
        if (!mainRoom.token && mainRoom.clanId) {
          dispatch(
            getAllLiveKitTokensThunk([
              ...params,
              convertRoomNameToParams(mainRoom.livekitName),
            ])
          );

          return;
        }

        dispatch(getAllLiveKitTokensThunk(params));
      }
    } else {
      if (!mainRoom.token && mainRoom.clanId) {
        dispatch(
          getAllLiveKitTokensThunk([
            convertRoomNameToParams(mainRoom.livekitName),
          ])
        );

        return;
      }
    }
  }, [roomList, mainRoom]);

  const remainingList =
    filteredRoom.length > count
      ? count > 1
        ? filteredRoom.slice(count)
        : filteredRoom
      : [];

  const treeRooms = convertToRoomTree(remainingList);

  const handleStreamSelect = (name: string) => {
    onSelectMainRoom(name);
  };

  const handleListOpen = () => {
    setOpenList(!isOpenList);
  };

  const startResizing = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    setResizing(true);
    setMousePositionY(e.clientY);
    setDragStartHeight(playerHeight);
  };

  const stopResizing = () => {
    setResizing(false);
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (!isResizing) return;

    const mouseDeltaY = e.clientY - mousePositionY;

    let newHeight = dragStartHeight + mouseDeltaY;

    if (newHeight < MIN_HEIGHT) {
      newHeight = MIN_HEIGHT;
    } else if (newHeight > maxHeight) {
      newHeight = maxHeight;
    }

    const newPanelHeight = viewportHeight - newHeight - PADDING - GAP;

    setPlayerHeight(newHeight);
    setPanelHeight(newPanelHeight);
  };

  const quantityHiddenRoom =
    filteredRoom.length - roomList.length - (count > 1 ? 1 : 0);

  return (
    <div
      className="flex flex-col gap-[8px]"
      onMouseUp={stopResizing}
      onMouseLeave={stopResizing}
      onMouseMove={handleMouseMove}
    >
      <div
        className="relative"
        style={{
          height: `${playerHeight}px`,
        }}
      >
        {breakPoint !== 'tablet' &&
          breakPoint !== 'mobile' &&
          breakPoint !== 'tablet-landscape' && (
            <div
              className="absolute h-[8px] w-[120px] rounded-[5px] left-1/2 -translate-x-1/2 bottom-0 bg-tpg_light z-[10] cursor-row-resize opacity-80"
              onMouseDown={startResizing}
              onMouseUp={stopResizing}
            />
          )}
        <Room
          key={livekitName}
          accountId={accountId}
          livekitName={livekitName}
          roomName={roomName}
          publisherName={publisherName}
          token={token}
          clanId={clanId || id}
          clanName={clanName}
          height={playerHeight}
          isMain={true}
          isMainBlock={true}
          onSelectMainRoom={onSelectMainRoom}
        />
      </div>
      <div
        className="relative grid gap-[8px]"
        style={{ gridTemplateColumns: `repeat(${count}, 1fr)` }}
      >
        {roomList.map(
          ({
            livekitName,
            publisherName,
            token,
            roomName,
            accountId,
            clanId,
            clanName,
          }) => (
            <div
              key={livekitName}
              className="cursor-pointer"
              onClick={() => handleStreamSelect(livekitName)}
            >
              <Room
                accountId={accountId}
                livekitName={livekitName}
                roomName={roomName}
                publisherName={publisherName}
                token={token}
                clanId={clanId || id}
                clanName={clanName}
                height={panelHeight}
                isMain={false}
                isMainBlock={true}
                onSelectMainRoom={onSelectMainRoom}
              />
            </div>
          )
        )}
        {!!remainingList.length && (
          <div
            ref={containerRef}
            className="relative flex items-center justify-center rounded-[10px] bg-light border-solid border-tpg_light border-[1px] cursor-pointer"
            style={{ minHeight: `${panelHeight}px` }}
            onClick={handleListOpen}
          >
            <span className="tpg-h4">{quantityHiddenRoom}</span>
            {isOpenList && (
              <RoomList rooms={treeRooms} onSelectMainRoom={onSelectMainRoom} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
