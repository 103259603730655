import { createAsyncThunk } from '@reduxjs/toolkit';

import { notify } from 'utils';

// Respons(E) - интерфейс ответа
// Reques(T) - интерфейс запроса

export const createThunk = <IResponse, IRequest = undefined>(
  name: string,
  asyncFunc: (data: IRequest) => Promise<{ data?: IResponse; error?: unknown }>,
  options?: {
    errorMessage?: string;
    callback?: (data: IResponse, request?: IRequest) => IResponse;
  }
) =>
  createAsyncThunk<IResponse, IRequest, { rejectValue: unknown }>(
    name,
    async (request, { rejectWithValue }) => {
      const { data, error } = await asyncFunc(request);

      if (error) {
        if (options?.errorMessage) {
          notify.error(options.errorMessage);
        }

        return rejectWithValue(error);
      }

      if (data && options?.callback) {
        return options.callback(data, request);
      }

      return data as IResponse;
    }
  );
